import React, {useEffect, useRef, useState} from 'react'
import './about.css'
import { Helmet } from 'react-helmet';

const About = () => {
  
  return (
    <div className='content_container'>
      <div>
        <h1 className='header'>Understanding Loans</h1>
      </div>
      <div className='contents'>
        <p>A loan is a financial arrangement where one party lends money to another party, usually with interest, for a predetermined period. Key features of loans include:</p>
        
        <ins data-public-key="cc024a09-6cdf-4aa7-951d-1a8a75a93c03" data-zone-id='OPTIONAL_FALLBACK_SUPPORT' data-label-id='898' data-label='loki_afd_block' data-profile-id='1063' data-param-subid="" data-param-site="" data-param-network=""></ins>
        <Helmet>
          <script async src="//newadserv.convertingtraffic.com/vigyapan/nsajs.php"></script>
        </Helmet>
        <ul>
            <li><strong>Principal:</strong> The initial amount borrowed, which must be repaid along with interest.</li>
            <li><strong>Interest:</strong> The cost of borrowing money, typically expressed as an annual percentage rate (APR).</li>
            <li><strong>Repayment Period:</strong> The duration over which the loan must be repaid, including any grace periods or installment schedules.</li>
            <li><strong>Collateral:</strong> Some loans may require collateral, such as property or assets, to secure the loan against default.</li>
            <li><strong>Terms and Conditions:</strong> The specific terms and conditions of the loan agreement, including fees, penalties, and repayment options.</li>
        </ul> 
        <ins data-public-key="0988b4cd-954f-41cb-bcdd-f2ea0d663f18" data-zone-id='OPTIONAL_FALLBACK_SUPPORT' data-label-id='979' data-label='muthuafsasdassddsfsdf' data-profile-id='1144' data-param-subid="" data-param-site="" data-param-network=""></ins>
        <Helmet>
          <script async="async" src="https://www.google.com/adsense/search/ads.js"></script>
          <script async src="//newadserv.convertingtraffic.com/vigyapan/nsajs.php"></script>
        </Helmet>
        <h2>Interest Rates</h2>
        <p>Interest rates on loans can vary based on factors such as the type of loan, the borrower's creditworthiness, prevailing market rates, and economic conditions. Common types of interest rates include:</p>
        <ul>
            <li><strong>Fixed Interest Rate:</strong> The interest rate remains constant throughout the loan term, providing predictability in monthly payments.</li>
            <li><strong>Variable Interest Rate:</strong> The interest rate fluctuates based on changes in market conditions, potentially resulting in fluctuating monthly payments.</li>
            <li><strong>Annual Percentage Rate (APR):</strong> The total cost of borrowing money, including interest and fees, expressed as an annual percentage.</li>
        </ul>
        <h2>Types of Loans</h2>
        <p>There are various types of loans available to meet different financial needs:</p>
        <ul>
            <li><strong>Personal Loans:</strong> Used for personal expenses such as home improvements, debt consolidation, or unexpected expenses.</li>
            <li><strong>Mortgages:</strong> Loans used to purchase real estate properties, with the property serving as collateral.</li>
            <li><strong>Auto Loans:</strong> Financing for the purchase of vehicles, typically secured by the vehicle itself.</li>
            <li><strong>Student Loans:</strong> Designed to cover educational expenses, with various repayment options and interest rates.</li>
            <li><strong>Business Loans:</strong> Funding for business purposes such as startup capital, expansion, or working capital.</li>
        </ul>

        <p>Each type of loan has its own eligibility criteria, terms, and repayment options, so it's essential to research and compare options before borrowing.</p>

      </div>
    </div>
  )
}

export default About