import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home/Home';
import About from './pages/about/About';
import { Contact } from './pages/contact/Contact';
import Navbar from './components/navbar/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
      <Navbar />
        <Routes>
            <Route
                path="/"
                element={<Home />}
            ></Route>
            <Route
                path="/about"
                element={<About />}
            ></Route>
            <Route
                path="/contact"
                element={<Contact />}
            ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
