import React from 'react'
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import {default as Bootstrapnav} from 'react-bootstrap/Navbar';
import './navbar.css'

const Navbar = () => {

  return (
    <Bootstrapnav bg="primary" data-bs-theme="dark">
        <Container>
          <Bootstrapnav.Brand href="#home">Dinero SPA</Bootstrapnav.Brand>
          <Nav className="me-auto">
            <Nav.Link><Link to="/">Home</Link></Nav.Link>
            <Nav.Link><Link to="/about">About</Link></Nav.Link>
            <Nav.Link><Link to="/contact">Contact</Link></Nav.Link>
          </Nav>
      </Container>
    </Bootstrapnav>
      
  )
}

export default Navbar